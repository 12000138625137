<template>
  <v-container column justify-start align="top" fluid>
    <v-snackbar v-model="showSnack" top light :timeout="2000">
      {{ snackText }}
    </v-snackbar>
    <v-dialog v-model="showNutrientSelection" max-width="600" min-height="400" persistent>
      <v-card>
        <v-card-title>Select which nutrient/metrics you would like to see</v-card-title>
        <v-card-text v-if="nutrients">
          <v-autocomplete
            multiple
            :items="nutrients"
            v-model="nutrientIds"
            item-value="nutrientnameid"
            item-text="nutrientname"
            chips
            deletable-chips
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showNutrientSelection=false">Cancel</v-btn>
          <v-btn @click="updateNutrientColumns">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ReplaceIngredientDialog
      v-if="replaceSelected"
      :cancel-replace-ingredients="cancelReplaceIngredients"
      :close-replace-ingredients="closeReplaceIngredients"
      :replace-selected="replaceSelected"
      :replacement="replacement"
      :show-replace-ingredients="showReplaceIngredients"/>
    <v-row>
      <v-col>
        <v-row class="px-2">
          <v-text-field
            style="height: 32px"
            v-model="searchInput"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-spacer/>
          <v-text-field
            label="Jump to page"
            single-line
            type="number"
            v-model="pageJump"
            :min="1"
            :max="pageCount"
            @blur="doPageJump"
            @keyup.native.enter="doPageJump"
          />
          <v-spacer/>
          <v-toolbar-items v-if="selected.length>1">
            <v-autocomplete
              small-chips
              label="Apply tags to multiple ingredients"
              v-model="multipleItemTags"
              multiple
              item-value="id"
              item-text="name"
              :items="tags"
              return-object
            />
            <v-btn icon @click="addTags({ids: selectedIds,tags: multipleItemTags})">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
            <v-btn icon @click="removeTags({ids: selectedIds,tags: multipleItemTags})">
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
          </v-toolbar-items>
          <v-spacer/>
          <v-checkbox class="pr-2" v-model="search.onlyVerified" label="Only Verified"></v-checkbox>
          <v-checkbox class="pr-2" v-model="search.onlyYieldSet" label="Yield is set"></v-checkbox>
          <v-checkbox v-model="search.onlyComponents" label="Show components"></v-checkbox>
          <v-toolbar-items class="mb-1">
            <v-btn icon @click="showNutrientSelection=true"
                   :color="this.nutrientIds.length>0 ?'primary':''">
              <v-icon>mdi-gauge</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-pagination
          class="mx-2"
          :length="pageCount"
          v-model="page"
          total-visible="20"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="itemsFormatted"
          :options.sync="options"
          :server-items-length="totalCount"
          :loading="loading"
          :footer-props="footerProps"
          class="mb-15"
          dense
          :show-select="!readOnly"
          v-model="selected"
          item-key="foodid"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-for="id of nutrientIds"
                    v-slot:[`item.${id}`]="{ item }">
            <v-text-field
              v-if="!readOnly"
              class="mt-2 mb-0"
              reverse
              single-line
              v-bind:key="id"
              v-model="item[id]"
              type="number"
              @change="updateNutrientValue(item.foodid, id,item)"
            />
            <span v-if="readOnly" v-bind:key="id">{{ item[id] }}</span>
          </template>
          <template v-slot:item.price="{ item }">
            {{ formatCurrency(item.price ? item.price / 10 : '') }}
          </template>
          <template v-slot:item.verified="{ item }">
            <v-checkbox
              v-model="item.verified"
              @change="saveVerified(item)"
              :disabled="readOnly"
            />
          </template>
          <template v-slot:item.cooking_method="{ item }">
            <v-autocomplete
              v-model="item.new_cooking_method_id"
              :items="cooking_methods"
              item-value="id"
              item-text="name"
              @change="saveCookingMethod(item)"
              :disabled="readOnly"
            />
          </template>
          <template v-slot:item.shopping_food="{ item }">
            <IngredientAutocomplete
              v-if="!readOnly"
              :ingredient="item.shopping_food"
              v-on:close="updateShoppingFood(item,$event)"
              :click-outside-close="false"
              :clearable="true"
            />
            <span v-if="readOnly">{{ item.shopping_food ? item.shopping_food.fooddescriptionsimple : '' }}</span>

          </template>

          <template v-slot:item.name="{ item }">
            <v-text-field
              v-if="item.showEditName"
              v-model="item.editName"
              dense
              single-line
              class="table-input"
              @change="saveName(item)"
              autofocus
              @blur="item.showEditName=false"
            />
            <span v-if="!item.showEditName">
              <strong>{{
                  item.nameOnLabel.toLowerCase()
                }}</strong>{{ item.name.replace(item.nameOnLabel, '').split(':::')[0] }}
            <span v-if="item.name.split(':::')[1]">
              <br/>
              {{ item.name.split(':::')[1] }}
            </span>
            </span>
          </template>
          <template v-slot:item.shoppingmultiplier="{ item }">
            <!--            <span v-if="item.shoppingmultiplier">-->
            <!--              {{ item.percentChange }}%-->
            <!--            </span>-->
            <v-text-field
              v-model="item.percentChange"
              suffix="%"
              dense
              @change="updatePercentChange({ingredient : item})"
              :disabled="readOnly"
            />
          </template>

          <template v-slot:item.component.inserted_at="{ item }">
            <span v-if="item.component">
              {{ formatDate(item.component.inserted_at) }}
            </span>
          </template>
          <template v-slot:item.fooddateofentry="{ item }">
            <span v-if="item.fooddateofentry">
              {{ formatDate(item.fooddateofentry) }}
            </span>
          </template>
          <template v-slot:item.food_name_tags="{ item }">
            <!--             <span v-for="food_name_tag  of item.food_name_tags" v-bind:key="food_name_tag ">-->
            <!--              {{ food_name_tag }}-->
            <!--            </span>-->
            <v-autocomplete
              dense
              v-model="item.food_name_tags"
              multiple
              item-value="id"
              item-text="name"
              :items="tags"
              return-object
              @change="setTags({ids : [ item.foodid], tags:  item.food_name_tags})"
              :disabled="readOnly"
              single-line
              hide-details
              solo
              flat
            >
              <template #selection="{ item }">
                <v-chip small class="ma-1" outlined>{{ item.name }}</v-chip>
              </template>
            </v-autocomplete>
          </template>
          <template v-slot:item.actions="{ item }" v-if="!readOnly">
            <v-btn class="ma-1" text outlined x-small @click="item.showEditName=true">Edit</v-btn>
            <v-btn class="ma-1" text outlined x-small @click="showReplaceItem(item)">Replace</v-btn>
            <v-btn class="ma-1" text outlined x-small @click="cloneIngredient(item)">Clone</v-btn>
          </template>
        </v-data-table>

        <!--        <v-data-table-->
        <!--            :search="search"-->
        <!--            :headers="headers"-->
        <!--            :items="items"-->
        <!--            :options.sync="options"-->
        <!--            :server-items-length="totalCount"-->
        <!--            :loading="loading"-->
        <!--            class="mb-15"-->
        <!--            show-group-by-->
        <!--        />-->
      </v-col>
    </v-row>
  </v-container>
  <!--    </v-layout>-->
  <!--  </v-container>-->
</template>

<script>
import api from '@/api';
import moment from 'moment';
import {mapActions} from 'vuex';
import ReplaceIngredientDialog from '@/components/ReplaceIngredientDialog';
import IngredientAutocomplete from '@/components/IngredientAutocomplete';
import {formatCurrency} from "../store/utils";
import urlState from "@/router/urlState";

function buildSearchQuery(search) {
  if (!search) {
    return undefined;
  } else {
    const where = {
      OR: [
        {
          fooddescriptionsimple: {
            contains: search,
            mode: 'insensitive',
          },
        },
        {
          fooddescription: {
            contains: search,
            mode: 'insensitive',
          },
        },
        {
          component: {
            name: {
              contains: search,
              mode: 'insensitive',
            }
          },
        },
      ],
    }

    const searchInt = parseInt(search);
    if (!isNaN(searchInt) && `${searchInt}` === search)
      where.OR.push(...[
          {
            component_id: {
              equals: searchInt,
            }
          }
        ]
      );
    return where;
  }
}

export default {
  components: {IngredientAutocomplete, ReplaceIngredientDialog},
  data() {
    return {
      page: 1,
      pageCount: 1,
      pageJump: '',
      selected: [],
      tags: [],
      totalCount: 0,
      items: [],
      loading: false,
      options: {
        sortBy: ['name']
      },
      search: {
        text: '',
        onlyComponents: false,
        onlyYieldSet: false,
        onlyVerified: true
      },
      footerProps: {
        itemsPerPageOptions: [10, 20, 100, 500, 1000, -1]
      },
      skipNextLoad: false,
      multipleItemTags: [],
      readOnly: false,
      showReplaceIngredients: null,
      replacement: null,
      replaceSelected: [],
      ingredientToMealMap: null,
      nutrientIds: [],
      showNutrientSelection: false,
      nutrients: undefined,
      nutrientIdLocalStorageKey: 'ingredients-view-nutrient-ids',
      extraColumns: [],
      nutrientUnits: {},
      nutrientsById: {},
      showSnack: null,
      snackText: null,
      cooking_methods: [],
      searchInput: null,
      repeatLoading: null
    }
  },
  watch: {
    page(val) {
      this.pageJump = val;
    },
    search: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    searchInput(v) {
      this.search.text = v;
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mixins: [urlState],
  async mounted() {
    this.syncToUrl({
      param: 'searchInput', urlParam: 'search', initFromRoute: true,
    });
    this.syncToUrl({
      param: 'page', urlParam: 'page', initFromRoute: true,
      parseCallback: (v) => v && Number(v)
    });

    this.readOnly = !!this.$route.meta.readOnly;
    try {
      const str = localStorage.getItem(this.nutrientIdLocalStorageKey);
      if (str) {
        this.nutrientIds = JSON.parse(str);
      }
    } catch (e) {
      console.error('failed to restore nutrient ids', e);
    }

    await Promise.all([api.get(`nutrients`)
      .then(result => {
        this.nutrients = result.data;
        this.nutrients.forEach(n => {
          this.nutrientUnits[n.nutrientnameid] = n.unit;
          this.nutrientsById[n.nutrientnameid] = n;
        })
      }),
      this.fetchCookingMethods(),
      this.fetchFoodTags()
        .then(tags => this.tags = tags.sort((a, b) => a.name.localeCompare(b.name)))
    ]);
    // use next tick because search param may not be set yet
    this.$nextTick(async () => {
      await Promise.all([
          this.getDataFromApi(),

        ]
      );
      this.addNutrientColumns(this.nutrientIds);
    })
  },
  methods: {
    formatCurrency,
    ...mapActions([
      'fetchComponent',
      'fetchFoodTags',

      'updateIngredientTags',
      'updatePercentChange',
      'replaceIngredient',
      'updateIngredientPartial'
    ]),
    async saveName(item) {
      console.log('saving name ', item);
      await this.updateIngredientPartial({ingredient: item, update: {fooddescriptionsimple: item.editName}});
      await this.getDataFromApi();
    },
    async saveVerified(item) {
      console.log('saving name ', item);
      await this.updateIngredientPartial({ingredient: item, update: {verified: item.verified}});
      await this.getDataFromApi();
    },
    async addTags({ids, tags}) {
      console.log('add tags', {ids, tags})
      await Promise.all(ids.map(id =>
        this.updateIngredientTags({
          ingredient:
            {foodid: id},
          update: {
            add: tags.map(t => t.id)
          }
        })));
      this.getDataFromApi();
    },
    async removeTags({ids, tags}) {
      console.log('remove tags', {ids, tags})
      await Promise.all(ids.map(id =>
        this.updateIngredientTags({
          ingredient:
            {foodid: id},
          update: {
            remove: tags.map(t => t.id)
          }
        })))
      this.getDataFromApi();
    },
    async setTags({ids, tags}) {
      console.log('set tags', {ids, tags})
      await Promise.all(ids.map(id =>
        this.updateIngredientTags({
          ingredient:
            {foodid: id},
          update: {
            set: tags.map(t => t.id)
          }
        })))
      this.getDataFromApi();
    },
    getDataFromApi() {
      if (this.skipNextLoad) {
        console.log('skip load');
        this.skipNextLoad = false;
        return;
      }

      // console.log('get data from api', this.options, this.search);

      const {sortBy, sortDesc, page = 1, itemsPerPage = 10} = this.options
      // console.log('options', {sortBy, sortDesc, page, itemsPerPage});
      const from = (page - 1) * itemsPerPage;
      const limit = itemsPerPage;
      let where = buildSearchQuery(this.search.text);
      const notNullComponent = {component_id: {not: null}};
      const nullComponent = {component_id: null};
      // const nullYield = {shoppingmultiplier: null};
      // NOTE: 1 = 0% change
      const notZeroYield = {shoppingmultiplier: {not: 1}};
      // const notNullYield = {shoppingmultiplier: {not: null}};
      // const notNullCategory = {category: {not: null}};
      // const nullCategory = {category: null};
      if (where) {
        where = {
          AND: [
            this.search.onlyComponents ? notNullComponent : nullComponent,
            this.search.onlyYieldSet ? notZeroYield : undefined,
            this.search.onlyVerified ? {verified: true} : undefined,
            // this.search.onlyCategories ? notNullCategory : nullCategory,
            where
          ]
            //filter out the undefineds
            .filter(c => !!c)
        };
        if (this.search.onlyYieldSet) {
          where.AND.push(notZeroYield);
        }
      } else {
        // where = this.search.onlyComponents ? notNullComponent : nullComponent;
        where = {
          AND: [
            this.search.onlyComponents ? notNullComponent : nullComponent,
            this.search.onlyYieldSet ? notZeroYield : undefined,
            this.search.onlyVerified ? {verified: true} : undefined,
            //     this.search.onlyCategories ? notNullCategory : nullCategory,
          ]   //filter out the undefineds
            .filter(c => !!c)
        };
        if (this.search.onlyYieldSet) {
          where.AND.push(notZeroYield);
        }
      }

      const mappedSortKeys = {
        // lastUsedInMeal : 'meal_food.meal.date',
        name: 'fooddescriptionsimple',
        mealCount: 'meal_food.count'
      };
      // console.log('fetching', {
      //   from,
      //   limit,
      //   sortBy: sortBy.map(key => mappedSortKeys[key] ? mappedSortKeys[key] : key),
      //   sortDesc,
      //   where
      // });
      const params = {
        from,
        limit,
        sortBy: sortBy.map(key => mappedSortKeys[key] ? mappedSortKeys[key] : key),
        sortDesc,
        where,
      };
      if (this.nutrientIds.length > 0) {
        params.nutrients = this.nutrientIds;
      }


      const stringifiedParams = JSON.stringify(params);
      if (this.loading) {
        console.log('already loading');
        this.repeatLoading = stringifiedParams;
        return;
      }
      this.loading = true;
      this.$set(this, 'items', []);
      const promises = [
        api.get(`v2/food/`, {
          params
        })
          .then(response => {
            let {data: {rows, total}} = response;
            // console.log('got data', {rows, total});
            rows.forEach(row => row.new_cooking_method_id = row.cooking_method_id);
            this.$set(this, 'items', rows);
            this.totalCount = total;
            if (itemsPerPage > -1) {
              const lastPage = Math.floor(total / itemsPerPage) + 1;
              if (page > lastPage) {
                console.log('setting last page');
                this.options.page = lastPage;
                this.skipNextLoad = true;
              }
            }
          })
          .finally(() => this.loading = false),
      ];
      return Promise.all(promises)
        .finally(() => {
          if (this.repeatLoading && this.repeatLoading !== stringifiedParams) {
            console.log('repeating load', stringifiedParams);
            console.log('repeating load', this.repeatLoading)
            this.repeatLoading = false;
            return this.getDataFromApi();
          } else {
            // console.log('no need to repeat search', this.repeatLoading);
          }

        });
    },
    formatDate(date) {
      return moment(date).format(moment.HTML5_FMT.DATE)
    },
    cancelReplaceIngredients() {
      this.showReplaceIngredients = false;
      this.replacement.ingredient = false;
      this.replaceSelected = [];
    },
    closeReplaceIngredients() {
      this.$nextTick(() => {
        const promises = [];
        if (this.showReplaceIngredients) {
          this.showReplaceIngredients = false;

          if (!this.replacement.ingredient) {
            alert('no replacement ingredient selected');
            return;
          }
          const newFoodId = this.replacement.ingredient.foodid;

          if (confirm(`Are you sure?  There is no undo, so be careful...`)) {
            this.loading = true;
            for (const item of this.replaceSelected) {
              const oldFoodId = item.foodid;
              console.log('replacing selected ', JSON.stringify(item));
              for (const {component_id, meal} of item.meal_food) {
                // need to do this to look up if it belongs to a component
                console.log('selected found in ', JSON.stringify(meal));
                promises.push(this.replaceIngredient(
                  {mealId: meal.id, oldFoodId, newFoodId, componentId: component_id}
                ))
              }
            }
          }
        }
        // need to clear this since after replacement old ingredients will still be selected (but no longer visible in the table!)
        this.replaceSelected = [];

        return Promise.all(promises)
          .then(this.getDataFromApi())
          .finally(() => this.loading = false);
      });
    },
    showReplaceItem(item) {
      console.log('replace item', item);
      this.replaceSelected = [item];
      this.replacement = {};
      this.showReplaceIngredients = true;
    },

    doPageJump() {
      this.page = parseInt(this.pageJump > this.pageCount ? this.pageCount : this.pageJump);
    },
    updateNutrientColumns() {
      localStorage.setItem(this.nutrientIdLocalStorageKey, JSON.stringify(this.nutrientIds));
      this.addNutrientColumns(this.nutrientIds);
      this.showNutrientSelection = false;
      return this.getDataFromApi();
    },
    addNutrientColumns(nutrientIds) {
      try {
        this.extraColumns = this.extraColumns.filter(c => !c.isNutrientColumn);
        nutrientIds.forEach(id => {
          const n = this.nutrients.find(n => n.nutrientnameid === id);
          this.extraColumns.push({
            text: `${n.nutrientname} (${n.unit} per 100g)`,
            value: `${id}`,
            isNutrientColumn: true
          })
        });
      } catch (e) {
        console.log('this.nutrients', this.nutrients);
        console.error('failed', e);
      }
    },
    updateNutrientValue(foodid, nutrientnameid, item) {
      const n = this.nutrientsById[nutrientnameid];
      let value = item[nutrientnameid];
      value = Number(value).toFixed(n.nutrientdecimals);
      item[nutrientnameid] = value;
      console.log('saving change', {foodid, nutrientnameid, value});
      const update = {};
      update[nutrientnameid] = value;
      api.post(`/v2/food/${foodid}/nutrients`, update)
        .then(r => {
          this.showSnack = true;
          this.snackText = `Updated ${n.nutrientname} to ${value} ${n.unit}`;
          console.log('success', r);
        });
    },
    async saveCookingMethod(item) {
      console.log('saving cooking method', item);
      let newCookingMethodId = item.new_cooking_method_id;
      if (newCookingMethodId === -1) {
        const newMethodName = prompt('enter the name of the new cooking method');
        if (newMethodName) {
          const result = await api.post('v2/food/cooking_method', {name: newMethodName});
          console.log('got result', result)
          const {data: cookingMethod} = result;
          console.log('cooking method ', cookingMethod)
          newCookingMethodId = cookingMethod.id;
          await this.fetchCookingMethods();
        } else {
          item.new_cooking_method_id = item.cooking_method_id;
          return;
        }
      } else {
        // newCookingMethodId = item.new_cooking_method_id.id;
      }
      const result = await api
        .post(`foodname/${item.foodid}/partial`, {cooking_method_id: newCookingMethodId});

      item.new_cooking_method_id = newCookingMethodId;
      item.cooking_method_id = newCookingMethodId;
      console.log('saved', result)
    },

    async fetchCookingMethods() {
      await api.get('v2/food/cooking_methods')
        .then(r => {
          console.log('got cooking_methods', r.data);
          this.cooking_methods = [{id: -1, name: 'Add a new method'}, ...r.data];
        });
    },
    updateShoppingFood(item, update) {
      const shoppingFoodid = (update && update.foodid) || null;
      if ((update || update === null) && shoppingFoodid !== item.shopping_foodid) {
        console.log('updateShoppingFood', {item, update});
        item.shopping_food = update;
        item.shopping_foodid = shoppingFoodid;
        return api.post(`foodname/${item.foodid}/partial`, {shopping_foodid: item.shopping_foodid});
      }
    },
    async cloneIngredient(item) {
      console.log('cloning ingredient', item);
      const result = await api.post(`foodname/${item.foodid}/clone`);
      console.log('clone result', result.data);
      this.$nextTick(() => this.getDataFromApi());
    }
  },
  computed: {
    headers() {
      const onlyComponents = this.search.onlyComponents;
      const headers = [
        {text: 'component id', value: 'component_id', width: '80px'},
        {text: 'id', value: 'foodid', width: '80px'},
        {text: 'verified', value: 'verified', width: '90px'},
        {text: 'component name', value: 'component.name'},
        {text: 'component created', value: 'component.inserted_at'},
        {text: 'name', value: 'name'},
        {text: 'Change %', value: 'shoppingmultiplier', width: '80px'},
        {text: 'last used in meal', value: 'lastUsedInMeal', width: onlyComponents ? '80px' : '', sortable: false},
        ...this.extraColumns,
        {text: 'meal count', value: 'mealCount', width: onlyComponents ? '80px' : ''},
        {text: 'cost (100g)', value: 'price'},
        {text: 'cost updated', value: 'priceDate'},
        {text: 'cooking method', value: 'cooking_method', width: '180px'},
        {text: 'shopping food', value: 'shopping_food', width: '180px'},
        {text: 'tags', value: 'food_name_tags', sortable: false, width: '300px'},
        {text: '', value: 'actions', sortable: false, width: '180px'}
      ]

      if (!onlyComponents) {
        return headers.filter(h => h.text.indexOf('component') === -1)
      } else {
        return headers;
      }

    },
    itemsFormatted() {
      function findLastUsed(meal_foods) {
        const dates = meal_foods
          .filter(mf => mf.meal && mf.meal.date)
          .map(mf => moment(mf.meal.date))
          .sort()
        // .reverse();


        if (dates.length > 1) {
          // console.log('last used', meal_foods);
          // console.log('dates', dates[0], dates[dates.length - 1]);
        }
        return dates[0] && dates[0].fromNow()
      }

      const items = this.items
        .map(item => {
          const itemFormatted = {
            ...item,
            name: item.fooddescriptionsimple + //` [${item.foodid}]` +
              // yeah, a little hacky, but this means we can text search.  in the template ::: is replaced with <br/>
              (item.fooddescriptionsimple !== item.fooddescription ? ':::' + item.fooddescription : ''),
            nameOnLabel: item.fooddescriptionsimple.split(',')[0],
            showEditName: false,
            editName: item.fooddescriptionsimple,
            percentChange: item.shoppingmultiplier ? ((item.shoppingmultiplier - 1) * 100).toFixed() : '',
            mealCount: item.meal_food.length,
            lastUsedInMeal: findLastUsed(item.meal_food),
            food_name_tags: item.food_name_tags.map(fnt => ({id: fnt.tag})),
            priceDate: item.priceDate && moment(item.priceDate).format('LL'),
          };
          (item.nutrient_amount || [])
            .forEach(amount => {
              const n = this.nutrientsById[amount.nutrientnameid];
              itemFormatted[amount.nutrientnameid] = amount ? amount.nutrientvalue.toFixed(n.nutrientdecimals) : amount;
            });

          return itemFormatted
        });
      // console.log('items formatted', items);
      return items;
    },
    selectedIds() {
      return this.selected.map(s => s.foodid);
    }
  }
}
</script>

<style>
/*.v-data-table-header {*/
/*  position: fixed;*/
/*  width: 100vw;*/
/*  background: inherit;*/
/*}*/

.v-data-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: inherit;
}
</style>
